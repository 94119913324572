<template>
  <div class="company-container">
    <el-row :gutter="16">
      <el-col v-for="(item, index) in list" :key="index" :span="8">
        <div class="img-container">
          <img
            class="pic"
            :src="newBaseurl + item.companyLogo"
            :alt="item.companyName"
          />
        </div>
      </el-col>
    </el-row>
    <el-pagination
      class="fenYe"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page="pageIndex"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      list: [],
      total: 0,
      pageSize: 15,
      pageIndex: 1,
    };
  },
  mounted() {
    this.getEnterprise();
  },
  methods: {
    getEnterprise() {
      this.$axios({
        method: "post",
        url: "/laf/laf/lafCompany/tableList.ajax",
        data: {
          pageSize: this.pageSize,
          pageNo: this.pageIndex,
          condition: "-1",
        },
      })
        .then((res) => {
          this.list = res.rows;
          this.total = res.totalRows;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handlePageChange(index) {
      this.pageIndex = index;
      this.getEnterprise();
    },
  },
};
</script>

<style lang='scss' scoped>
.img-container {
  width: 100%;
  height: 110px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 25px;
}
.pic {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: contain;
}
.pages {
  text-align: center;
}
.fenYe {
  text-align: center;
  margin-top: 30px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $red;
}
</style>